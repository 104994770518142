import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _isEqual from 'fast-deep-equal'

// Import Components
import DeckGL, { IconLayer } from 'deck.gl'
import { TripsLayer } from '@deck.gl/geo-layers'
// import {IconLayer} from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl'

// Import Map Configs
import { MAP } from '../../Config/config'

class DGlMap extends React.PureComponent {
    state = {
        viewState: {
            longitude: 90.46027047540736,
            latitude: 23.76716255930807,
            zoom: 11.5,
            pitch: 0,
            bearing: 0,
        },
        layers: [],
        tooltip: {
            x: 0,
            y: 0,
            data: null,
        },
    }

    // Get Icon URL
    _onGetIconUrl = (d) => ({
        url:'yellow-pin.png',
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
        mask: true
      })

    componentDidUpdate = (prevProps) => {
        const { visitedPathData, endPointIconData } = this.props
        // console.log(endPointIconData[0],'endpoint')
        const { viewState } = this.state

        if (!_isEqual(prevProps.visitedPathData, visitedPathData) || !_isEqual(prevProps.endPointIconData, endPointIconData)) {

            const tripLayer = [
              new TripsLayer({
                id: "TripsLayer",
                data: visitedPathData,
                currentTime: 500,
                fadeTrail: false,
                capRounded: true,
                getColor: [0, 0, 0],
                getPath: (d) => d.waypoints.map((p) => p.coordinates),
                jointRounded: true,
                widthMinPixels: 3,
                opacity: 1,
              }),
              new IconLayer({
                id: "IconLayer",
                data: endPointIconData,
                getColor: [0, 128, 0],
                getIcon: this._onGetIconUrl,
                sizeScale: 4,
                getPosition: (d) => d.coordinates,
                getSize: 9,
                pickable: true,
              }),
            ];
            

            this.setState({
                layers: [ ...tripLayer ],
                tooltip: {
                    x: 0,
                    y: 0,
                    data: null,
                }
            })

            if (visitedPathData.length) {
                this.setState({
                    viewState: {
                        ...viewState,
                        longitude: visitedPathData?.[0]?.waypoints?.[0]?.coordinates[0],
                        latitude: visitedPathData?.[0]?.waypoints?.[0]?.coordinates[1],
                    }
                })
            }
        }
    }

    // creating deckgl path layer
    // _createPathlayer = (layerData) => {

    //     const tripLayer = new TripsLayer({
    //         id: 'TripsLayer',
    //         data: layerData,
    //         currentTime: 500,
    //         getTimestamps: d => d.waypoints.map(p => p.timestamp - 1554772579000),
    //         trailLength: 600,
    //         capRounded: true,
    //         getColor: [253, 128, 103],
    //         getPath: d => d.waypoints.map(p => p.coordinates),
    //         jointRounded: true,
    //         widthMinPixels: 8,
    //         opacity: 0.8,
    //     });

    //     this.setState({
    //         layers: tripLayer
    //     })
    // }

    // Hanlde View State Change
    _onViewStateChange = ({ viewState }) => {
        if (viewState) {
            this.setState({ viewState })
        }
    }

    // Handle Hover
    _onHover = ({ x, y, object }) => {
        this.setState({
            tooltip: {
                x,
                y,
                data: object,
            },
        })
    }

    renderTooltip() {
        const { tooltip } = this.state
        if (!tooltip.data) {
            return null
        }
        return (
          <div
            style={{
              position: "absolute",
              left: tooltip.x,
              top: tooltip.y,
              background: "white",
              padding: "5px",
              borderRadius: "3px",
              pointerEvents: "auto",
              userSelect: "text",
              zIndex: 1,
            }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div>
              <strong>Trip ID:</strong>{" "}
              <span style={{ userSelect: "text" }}>{tooltip.data.id}</span>
            </div>
            <div>
              <strong>End Time:</strong>{" "}
              <span style={{ userSelect: "text" }}>
                {tooltip.data.end_time}
              </span>
            </div>
          </div>
        );
    }


    render() {
        const { viewState, layers } = this.state
        return (
          <div>
            <DeckGL
              viewState={viewState}
              onViewStateChange={this._onViewStateChange}
              controller={true}
              layers={layers}
              onClick={this._onHover}
            >
              <StaticMap mapStyle={MAP.STYLE} />
            </DeckGL>
            {this.renderTooltip()}
          </div>
        );
    }
}

DGlMap.propTypes = {
    loading: PropTypes.bool,
    visitedPathData: PropTypes.array,
    endPointIconData: PropTypes.array
}
DGlMap.defaultProps = {
    loading: false,
    visitedPathData: [],
    endPointIconData: []
}

const mapStateToProps = (state) => ({
    loading: state?.routeStatistics?.loading ?? false,
})

export default connect(mapStateToProps, null)(DGlMap)