import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import DateOrgFilterTaskMap from "../DateOrgFilterTaskMap"
import DGlMap from "../../Common/DGlMap"
import RouteLegend from "../RouteLegend"

class RouteMapContainer extends PureComponent {

    render() {
        const { visitedPathData, endPointIconData, routeStatistics } = this.props
        return (
            <div style={mapBoxStyle}>
                <DateOrgFilterTaskMap
                    disableOrg={true}
                    disableCity={true}
                    disableTabs={true}
                    disableFileInput={true}
                    disableAllVerifier={true}
                />
                <RouteLegend routeStatistics={routeStatistics} />
                <DGlMap
                    visitedPathData={visitedPathData}
                    endPointIconData={endPointIconData}
                />
            </div>
        )
    }

}

// Styles
const mapBoxStyle = {
    minHeight: `${window.innerHeight - 170}px`,
    position: 'relative',
    height: '400px',
    overflow: 'hidden',
}

// PropTypes
RouteMapContainer.propTypes = {
    visitedPathData: PropTypes.array,
    endPointIconData: PropTypes.array,
    routeDistance: PropTypes.string
}
RouteMapContainer.defaultProps = {
    visitedPathData: [],
    endPointIconData: [],
    routeDistance: ''
}

// Map State To Props
const mapStateToProps = (state) => ({
    visitedPathData: state?.routeStatistics?.visitedPathData ?? [],
    endPointIconData: state?.routeStatistics?.endPointIconData ?? [],
    routeDistance: state?.routeStatistics?.routeDistance ?? 0
})

export default connect(mapStateToProps, null)(RouteMapContainer)