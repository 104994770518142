import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    visitedPathData: [],
    endPointIconData: [],
    routeDistance: '',
    centerPoint: []
}

const routeStatisticsSlice = createSlice({
    name: 'routeStatistics',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setVisitedPathData: (state, action) => {
            state.visitedPathData = action.payload
        },
        setEndPointIconData: (state, action) => {
            state.endPointIconData = action.payload
        },
        setRouteDistance: (state, action) => {
            state.routeDistance = action.payload
        },
        setCenterPoint: (state, action) => {
            state.centerPoint = action.payload
        }
    }
})

export const { setVisitedPathData, setEndPointIconData, setRouteDistance, setCenterPoint } = routeStatisticsSlice.actions
export default routeStatisticsSlice.reducer