//********************************************* */
//NOTE
//this component is reused along with it's different parent in 'Add Concern'
// and 'Add Additional Trips' of ReCPV
// Therefore, Maintain Parent carefully
//****************************************** */
import React from 'react'
import PropTypes from 'prop-types'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

// Import  Components
import { CloseOutlined } from '@ant-design/icons'
import { Input, Card, Tooltip, Button, Switch, Space, Checkbox, Form } from 'antd'

import { getAutoCompleteAddress, getRupantorAddress, showAlert } from '../../../Services/Actions/commonActions'

// Debounce function
const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

// Debounced function
const getAutoCompleteAddressDebounced = debounce((value, setState, toggleSuggestionBox) => {
    getAutoCompleteAddress({ q: value })
        .then(res => {
            const places = res
            const results = places.map(item => {
                return {
                    exactAddress: item.address,
                    placeName: item.placename,
                    area: item.area,
                    latitude: item.latitude,
                    longitude: item.longitude
                }
            })
            setState({ results })
            toggleSuggestionBox(true)
        })
        .catch(err => {
            showAlert('error', err?.message ?? 'Operation Failed')
            console.error(err)
        })
}, 500);

class AutoCoompleteAddressReCPV extends React.PureComponent {
    state = {
        results: [],
        inputValue: '',
        rupantor: false,
        searchInput: ''
    }

    // call search api
    _onSearch = e => {
        const { toggleSuggestionBox, onTextareaInputChange } = this.props
        const value = e.target.value

        // setting input value to validate manatory field of form field
        onTextareaInputChange(value)

        if (value) {
            const { rupantor } = this.state

            this.setState({ inputValue: value })
            
            if (rupantor) {
                this._rupantorSearch(value)
            } 
            else {
                getAutoCompleteAddressDebounced(value, this.setState.bind(this), toggleSuggestionBox)
            }
        }
        else{
            this.setState({ inputValue: '' })
            toggleSuggestionBox(false)
        }
    }

    // Rupantor Search
    _rupantorSearch = (value) => {
        if (value) {
            const { toggleSuggestionBox } = this.props

            getRupantorAddress(value)
                .then(res => {
                    const rslt = res
                    const results = {
                        exactAddress: rslt.address,
                        area: rslt.area,
                        latitude: rslt.latitude,
                        longitude: rslt.longitude,
                        placeName: rslt.placename,
                    }
                    this.setState({ results: [results] })
                    toggleSuggestionBox(true)
                })
                .catch(err => {
                    showAlert('error', err?.message ?? 'Failed to load rupantor data') 
                    console.error(err)
                })
        }
    }

    //
    _handleSearchOption = checked => {
        this.setState({ rupantor: checked })
    }

    // select option from suggestion and close suggestion box
    _handleOptionSelect = (e) => {
        const { id, onSelect, toggleSuggestionBox } = this.props
        const { results } = this.state
        const selectedAddress = results[e.currentTarget.value]

        // Check if value and option is valid
        if(selectedAddress?.exactAddress) {
            const address = {
                address: selectedAddress.exactAddress,
                area: selectedAddress.area,
                latitude: selectedAddress.latitude,
                longitude: selectedAddress.longitude,
                id: id
            }

            // // Set address to search input and closse  ssuggestion box
            this.setState({ 
                inputValue: selectedAddress.exactAddress,
             })
             // calling parent func to close suggestion box
             toggleSuggestionBox(false)

            // Set to form via getExactAddress prop
            onSelect(address)
        }
    }

    //close suggestion box
    _handleSuggestionBoxCloseByProp = () => {
        const { toggleSuggestionBox } = this.props

        toggleSuggestionBox(false)
    }

    render() {
        const { handleNotExact, id, rule, customWidth, isSuggestionBoxOpenProp, isRequired } = this.props
        const { results, inputValue } = this.state

        return (
            <div>
                <Form.Item
                    label={`Exact Address`}
                    name={id}
                    rules={rule ? rule : [{
                        required: isRequired ? 
                            !inputValue ? true: false 
                            : 
                            false,
                        message: 'Exact address cannot be empty.'
                    }]}
                >
                    <Input.TextArea
                        style={{ width: customWidth ? customWidth : '100%' }}
                        placeholder={ 'Enter exact address..' }
                        rows={window.innerWidth > 768 ? 4 : 6 }
                        onChange={ this._onSearch }
                        value={ inputValue }
                        id={id}
                        name={id}
                    />
                    <br />
                    {
                        results?.length > 0 && isSuggestionBoxOpenProp &&
                        <Card style={STYLE.suggestionBox}  bodyStyle={{ padding: '5px 10px' }}>
                            <ul style={{padding: '3px'}}>
                                <Tooltip placement="left" title={'Close Suggestion'}>
                                    <Button onClick={this._handleSuggestionBoxCloseByProp} icon={ <CloseOutlined />} danger size={'small'} style={STYLE.closeButton}>
                                    </Button>
                                </Tooltip>
                            {
                                results.map((address, idx) => {
                                    const matches = match(address?.exactAddress, inputValue)
                                    const parts = parse(address?.exactAddress, matches)
                
                                    return (
                                        <li 
                                            style={STYLE.listItems}
                                            key={idx}
                                            value={idx}
                                            id={id}
                                            onClick={(e) => this._handleOptionSelect(e)}
                                        >
                                        {
                                            parts && parts.length > 0 &&
                                            parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    value={'address'}
                                                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                                                >
                                                    {part.text}
                                                </span>
                                                ))
                                        }
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </Card>
                    }
                </Form.Item>

                <div style={{ display: 'flex', margin: '0.5rem 0 0 0' }}>
                    <Space>
                        <Checkbox onChange={handleNotExact} name={id} >
                            {`Not Exact`}
                        </Checkbox>
                        <Switch
                            checkedChildren='Rupantor Search'
                            unCheckedChildren='Default Search'
                            onChange={this._handleSearchOption}
                        />
                    </Space>
                </div>
            </div>
        )
    }
}


const STYLE = {
    suggestionBox: {
        position: 'absolute', 
        zIndex: 10, 
        width: '99%'
    },
    closeButton: { 
        position: 'absolute', 
        right: '10px', 
        margin: 0, 
        backgroundColor: '#fff'
    },
    listItems: {
        listStyleType: 'none', 
        borderBottom: '1px solid #f2f2f2', 
        padding: '3px 0'
    }
}

// Props Validation
AutoCoompleteAddressReCPV.propTypes = {
    isSuggestionBoxOpenProp: PropTypes.bool,
    toggleSuggestionBox: PropTypes.func,
    customWidth: PropTypes.string,
    isRequired: PropTypes.bool,
    onTextareaInputChange: PropTypes.func
}

AutoCoompleteAddressReCPV.defaultProps = {
    isSuggestionBoxOpenProp: false,
    toggleSuggestionBox: () => null,
    customWidth: '100%',
    isRequired: false,
    onTextareaInputChange: () => null
}

export default AutoCoompleteAddressReCPV