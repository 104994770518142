// NOTES: PROP - USECASE
/*
    disableOrg -> boolean -> true to disable organization option
    disableCity -> boolean -> true to disable city option
    disableTabs -> boolean -> true to disable tab(segment) 
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import _isEqual from 'fast-deep-equal'

// Import Components
import { Card, DatePicker, Space, Select, Button, Popover, Segmented, Tooltip, Input } from 'antd'

// Import Icons
import { CloseCircleOutlined, MenuFoldOutlined } from '@ant-design/icons'

// Import Actions, and Reducers
import { getTripList, getAppointmentList } from '../../Services/Actions/taskMapActions'
import { getVerifierVisitedPath } from '../../Services/Actions/routeStatisticsActions'
import { isAllowedToAccess } from '../../Services/Actions/permissionActions'
import { setSelectedTripLayerType, setPreviousTaskMapFilterParams } from '../../Services/Reducers/taskMapReducer'
import { setEndPointIconData, setRouteDistance, setVisitedPathData } from '../../Services/Reducers/routeStatisticsReducer'
import { getStaticTripStatus } from '../../Services/Actions/staticOptionAction'


class DateOrgFilterTaskMap extends React.PureComponent {
    state = {
        startDate: moment(new Date(), 'yyyy-MM-DD'),
        endDate: moment(new Date(), 'yyyy-MM-DD'),
        // selectedGroup: { id: -1, name: 'All Org' },
        selectedGroup: {},
        cities: [
            { id: -1, name: 'All City', value: -1 },
            { id: 1, name: 'Dhaka', value: 'Dhaka' },
            { id: 2, name: 'Chattogram', value: 'Chattogram' },
            { id: 3, name: 'In Station', value: 'In' },
            { id: 4, name: 'Out Station', value: 'Out' },
        ],
        selectedCity: { id: -1, name: 'All City', value: -1 },
        selectedVerifier: {},
        selectedTripStatus: {},
        fileNo: null,
        tabOptions: [],
        varifiersWithoutAll: [],
        tripStatuses: [],
    }

    componentDidMount = () => {
        const { verifiers } = this.props
        this._verifiersListWithoutAll(verifiers);
        this._clearSelectedTripLayer();
        this._setTabOptions();
        this._setTripStatuses();
    }

    // If verifiers data received
    componentDidUpdate = (prevProps) => {
        const { verifiers } = this.props
        if (!_isEqual(prevProps.verifiers, verifiers)) {
            this._verifiersListWithoutAll(verifiers);
        }
    }

    // set trip statuses
    _setTripStatuses = () => {
        const tripStatuses = getStaticTripStatus();
        this.setState({ tripStatuses })
    }
    // verifiers list without All Verifiers Option
    _verifiersListWithoutAll = (verifiers) => {
        const varifiersWithoutAll = verifiers.slice(1);
        this.setState(() => ({ varifiersWithoutAll }))
    }

    // clear selected trip layer
    _clearSelectedTripLayer = () => {
        const { dispatch } = this.props
        dispatch(setSelectedTripLayerType(null))
    }

    // set tab options
    _setTabOptions = () => {
        const { allowedPermission } = this.props
        const tabOptions = []

        // PERMISSION ID OF "map-view.map-view.trip.list" is 39 
        if (isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '39' })) {
            tabOptions.push({ label: 'Regular', value: 'regular' })
        }

        // PERMISSION ID OF "map-view.map-view.appointment.list" is 37
        if (isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '35' })) {
            tabOptions.push({ label: 'Appointments', value: 'appointments' })
        }

        this.setState({ tabOptions })
    }

    // on start date change
    _onStartDateChange = date => {
        if (date) {
            const startDate = date
            this.setState({ startDate })
        }
    }

    // on end date change
    _onEndDateChange = date => {
        if (date) {
            const endDate = date
            this.setState({ endDate })
        }
    }

    // Handle Selected Org
    _handleSelectOrg = (value) => {
        const { orgList } = this.props
        const selectedGroup = orgList.find(org => org.uuid === value)
        this.setState({ selectedGroup })
    }

    // Handle Selected City
    _handleSelectedCity = (value) => {
        const { cities } = this.state
        const selectedCity = cities.find(city => city.value === value)
        this.setState({ selectedCity })
    }

    // Handle Selected verifier
    _handleSelectedVerifier = (value) => {
        const { verifiers } = this.props
        const selectedVerifier = verifiers.find(verifier => verifier.id === value)
        this.setState({ selectedVerifier })
    }

    // Handle Selected Trip Status
    _handleSelectedTripStatus = (value) => {
        const { tripStatuses } = this.state
        const selectedTripStatus = tripStatuses.find(status => status.value === value)
        this.setState({ selectedTripStatus })
    }

    // Handle File No
    _handleFileNo = (e) => {
        const fileNo = e.target.value;
        this.setState({ fileNo })
    }

    // Handle Task Map Filter
    _handleTaskMapFilter = () => {
        const { startDate, endDate, selectedGroup, selectedCity, selectedVerifier, selectedTripStatus, fileNo } = this.state
        const { dispatch, disableTabs, disableCity, disableOrg, disableVerifier, allowedPermission } = this.props

        // Format dates
        const formatedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
        const formatedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')

        // set params
        let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate, }
        if (selectedGroup && selectedGroup?.uuid !== -1) {
            params = { ...params, org_id: selectedGroup.uuid }
        }
        // if city value is not -1
        if (selectedCity && selectedCity?.id !== -1) {
            params = { ...params, city: selectedCity.value }
        }

        // if verifier value is not -1
        if (selectedVerifier && selectedVerifier?.id !== -1) {
            params = { ...params, agent_id: selectedVerifier.id } //agent and verifier are same
        }

        // if trip status value is not -1
        if (selectedTripStatus && selectedTripStatus?.id !== -1) {
            params = { ...params, status: selectedTripStatus.value }
        }

        // if file number exist
        if (fileNo) {
            let startDate = "";
            let endDate = "";
            this.setState({ startDate })
            this.setState({ endDate })
            params = { application_number: fileNo } //agent and verifier are same
        }

        // if Org, city and Tabs ar not disable[These are used in taskmap]
        if (!disableOrg && !disableCity && !disableTabs) {
            // storing the params to reload the taskmap data after reassigning
            dispatch(setPreviousTaskMapFilterParams(params))

            // PERMISSION ID OF "map-view.map-view.trip.list" is 39
            if (isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '37' })) {
                // Get trips 
                dispatch(getTripList(params))
            }

            // PERMISSION ID OF "map-view.map-view.appointment.list" is 37
            if (isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '35' })
                && !fileNo
            ) {
                // Get appointments 
                dispatch(getAppointmentList(params))
            }

            return
            //  Get summary for task map
            // dispatch(getAllSummary(params)) ==>  To be removed in future
        }
        // if Org, city and Tabs ar not disable[These are used in taskmap]
        if (!disableVerifier) {
            const { dispatch } = this.props
            dispatch(getVerifierVisitedPath(params))
        }

    }

    // Handle Trips Layer Filter Type
    _handleTripsLayerFilterType = value => {
        const { dispatch } = this.props
        dispatch(setSelectedTripLayerType(value))
    }

    // Handle Clear Data
    _handleClearData = () => {
        const { dispatch } = this.props
        const { verifiers } = this.props
        this.setState({
            startDate: moment(new Date(), 'yyyy-MM-DD'),
            endDate: moment(new Date(), 'yyyy-MM-DD'),
            selectedGroup: {},
            cities: [
                { id: -1, name: 'All City', value: -1 },
                { id: 1, name: 'Dhaka', value: 'Dhaka' },
                { id: 2, name: 'Chattogram', value: 'Chattogram' },
                { id: 3, name: 'In Station', value: 'In' },
                { id: 4, name: 'Out Station', value: 'Out' },
            ],
            selectedCity: { id: -1, name: 'All City', value: -1 },
            selectedVerifier: {},
            selectedTripStatus: {},
            fileNo: null,
            // tabOptions: [],
            varifiersWithoutAll: [],
        })

        this._verifiersListWithoutAll(verifiers);
        dispatch(setRouteDistance(0))
        dispatch(setVisitedPathData([]))
        dispatch(setEndPointIconData([]))

    }

    render() {
        const { startDate, endDate, selectedGroup, selectedCity, selectedVerifier, selectedTripStatus, cities, tabOptions, varifiersWithoutAll, tripStatuses } = this.state
        const { orgList, isDateOrgFilterLoading, selectedTripLayerType, verifiers, disableOrg, disableAllVerifier, disableFileInput, disableCity, disableTabs, allowedPermission, dispatch } = this.props

        return (
            <div style={styles.containerStyles}>
                <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {
                        !disableTabs &&
                        <Segmented
                            options={tabOptions}
                            value={selectedTripLayerType}
                            onChange={this._handleTripsLayerFilterType}
                            style={{ backgroundColor: 'rgb(118 121 119)', color: '#fff' }}
                        />

                    } {/* Verifiers */}
                    {/* <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder='Select Verifiers'
                        onChange={this._handleSelectedVerifier}
                        value={selectedVerifier.id}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp='children'
                    >
                        {
                            verifiers?.length > 0 &&
                            !disableAllVerifier &&
                            verifiers.map((item, index) => {
                                return (
                                    <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                )
                            })
                        }
                        {
                            varifiersWithoutAll?.length > 0 &&
                            disableAllVerifier &&
                            varifiersWithoutAll.map((item, index) => {
                                return (
                                    <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                )
                            })
                        }
                    </Select> */}
                    <Popover
                        trigger='click'
                        placement='bottomRight'
                        title='Find Trips'
                        content={
                            <Card bodyStyle={{ padding: 0 }} bordered={false} >
                                <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
                                    {/* Date Picker */}
                                    {
                                        // PERMISSION ID OF "map-view.map-view.filter.date" is 32
                                        isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '32' }) &&
                                        <div>
                                            <DatePicker
                                                onChange={this._onStartDateChange}
                                                format={'DD-MMM-yyyy'}
                                                value={startDate}
                                                allowClear={false}
                                            />
                                            <br />
                                            <DatePicker
                                                onChange={this._onEndDateChange}
                                                format={'DD-MMM-yyyy'}
                                                value={endDate}
                                                allowClear={false}
                                            />
                                        </div>
                                    }

                                    {/* Organization */}
                                    {
                                        // PERMISSION ID OF "map-view.map-view.filter.organization" is 33
                                        isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '33' }) &&
                                        !disableOrg &&
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            placeholder={'Select Org'}
                                            onChange={this._handleSelectOrg}
                                            value={selectedGroup?.uuid}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            optionFilterProp='children'
                                        >
                                            {
                                                orgList?.length > 0 && orgList.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index + 1} value={item.uuid}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    }

                                    {/* City */}
                                    {
                                        // PERMISSION ID OF "map-view.map-view.filter.city" is 36
                                        isAllowedToAccess(allowedPermission, { menuName: 'map-view', subMenuName: 'map-view', permissionID: '34' }) &&
                                        !disableCity &&
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            placeholder='Select City'
                                            onChange={this._handleSelectedCity}
                                            value={selectedCity.value}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            optionFilterProp='children'
                                        >
                                            {
                                                cities.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index + 1} value={item.value}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    }


                                    {/* Verifiers */}
                                    <Select
                                        style={{ width: '100%' }}
                                        // clearIcon={<CloseCircleOutlined />}
                                        // onClear={this.setState.bind(this, { selectedVerifier: {} })}
                                        showSearch
                                        placeholder='Select Verifiers'
                                        onChange={this._handleSelectedVerifier}
                                        value={selectedVerifier.id}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        optionFilterProp='children'
                                    >
                                        {
                                            verifiers?.length > 0 &&
                                            !disableAllVerifier &&
                                            verifiers.map((item, index) => {
                                                return (
                                                    <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                        {
                                            varifiersWithoutAll?.length > 0 &&
                                            disableAllVerifier &&
                                            varifiersWithoutAll.map((item, index) => {
                                                return (
                                                    <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>

                                    {/* Task Status */}
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        placeholder='Select Task Status'
                                        onChange={this._handleSelectedTripStatus}
                                        value={selectedTripStatus?.id}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        optionFilterProp='children'
                                    >
                                        {
                                            tripStatuses?.length > 0 &&
                                            tripStatuses.map((item, index) => {
                                                return (
                                                    <Select.Option key={index + 1} value={item.value}>{item.label}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    {/* File Number */}
                                    {
                                        !disableFileInput &&
                                        <Input onChange={this._handleFileNo} placeholder={'File Number..'} />
                                    }

                                    {/* Get Trips */}
                                    <Button
                                        block
                                        type='primary'
                                        loading={isDateOrgFilterLoading}
                                        onClick={this._handleTaskMapFilter}
                                    >
                                        Get Trips
                                    </Button>

                                    {/* Clear */}
                                    <Button
                                        block
                                        danger
                                        type='primary'
                                        onClick={this._handleClearData}
                                    >
                                        Clear
                                    </Button>
                                </Space>
                            </Card>
                        }
                    >
                        <Tooltip title={'Click to open options'} placement={'topRight'}>
                            <Button type={'primary'} icon={<MenuFoldOutlined />} danger />
                        </Tooltip>
                    </Popover>
                </Space>
            </div>
        )
    }
}

// JSS Styles
const styles = {
    containerStyles: {
        margin: '1rem',
        position: 'absolute',
        top: 10,
        right: 0,
        zIndex: 10,
    },
    userAvatarStyles: {
        backgroundColor: '#1890ff',
        verticalAlign: 'middle',
        cursor: 'pointer',
    }
}

// PropTypes
DateOrgFilterTaskMap.propTypes = {
    orgList: PropTypes.array,
    isDateOrgFilterLoading: PropTypes.bool,
    selectedTripLayerType: PropTypes.string,
    disableOrg: PropTypes.bool,
    disableCity: PropTypes.bool,
    disableTabs: PropTypes.bool,
    disableVerifier: PropTypes.bool,
    disableFileInput: PropTypes.bool,
    disableAllVerifier: PropTypes.bool
}

DateOrgFilterTaskMap.defaultProps = {
    orgList: [],
    isDateOrgFilterLoading: false,
    selectedTripLayerType: null,
    disableOrg: false,
    disableCity: false,
    disableTabs: false,
    disableVerifier: false,
    disableFileInput: false,
    disableAllVerifier: false,
    allowedPermission: null
}

// Map State To Props
const mapStateToProps = state => ({
    orgList: state.common.orgList,
    verifiers: state.common.verifiers,
    isDateOrgFilterLoading: state.common.isDateOrgFilterLoading,
    selectedTripLayerType: state.taskMap.selectedTripLayerType,
    allowedPermission: state?.permission?.allowedPermission ?? null,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(DateOrgFilterTaskMap)