import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Configs
import { BROKEN_IMG } from '../../../Config/config'

// Import Components
import { Card, Empty, Row, Col, Button, Badge, Image, Space } from 'antd'
import Spinner from '../../Common/Spinner'

// Import Actions
import { getAttachmentTypeLabel } from '../../../Services/Actions/commonActions'

class Documents extends React.PureComponent {
    state = {
        selectedAttachmentType: null,
        isLoading: false
    }

    componentWillUnmount = () => {
        this.setState({ selectedAttachmentType: null })
    }

    // Handle Attachment Type Selection
    _handleAttachmentTypeSelection = attachmentType => {
        const { selectedAttachmentType } = this.state

        // Set Loading State to true
        this.setState({ isLoading: true })

        // Check if the selected attachment type is already selected
        if (selectedAttachmentType !== attachmentType) {
            // Keep user to wait until the attachment type is loaded
            setTimeout(() => {
                this.setState({ selectedAttachmentType: attachmentType, isLoading: false })
            }, 1200)
        } else {
            this.setState({ selectedAttachmentType: null, isLoading: false })
        }
    }

    // Handle PDF Download
    _handlePDFDownload = url => {
        window.open(url, '_blank', 'noopener, noreferrer')
    }
    render() {
        const { selectedAttachmentType, isLoading } = this.state
        const { applicationDetails } = this.props
        console.log(applicationDetails, 'applicationDetails')

        return (
            <Card title={'Documents'} hoverable style={{ height: '100%' }}>
                {
                    applicationDetails?.attachments?.length > 0 ? (
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Row gutter={[12, 8]}>
                                {
                                    applicationDetails?.attachments?.map((attachment, index) => (
                                        <Col key={index + attachment.key}>
                                            <Badge
                                                key={index + attachment.key + '_badge'}
                                                count={attachment?.attachments?.length ?? 0}
                                                size={'small'}
                                                color={'green'}
                                            >
                                                <Button
                                                    key={index + attachment?.key + '_button'}
                                                    type={selectedAttachmentType === attachment?.type ? 'primary' : 'default'}
                                                    onClick={() => this._handleAttachmentTypeSelection(attachment?.type)}
                                                >
                                                    {getAttachmentTypeLabel(attachment?.type)}
                                                </Button>
                                            </Badge>
                                        </Col>
                                    ))
                                }
                            </Row>
                            {
                                isLoading ? (
                                    <div style={styles.containerStyles}>
                                        <Spinner />
                                    </div>
                                ) : (
                                    selectedAttachmentType && (
                                        <Card title={getAttachmentTypeLabel(selectedAttachmentType)}>
                                            <Row gutter={[4, 4]}>
                                                {
                                                    applicationDetails?.attachments?.map((attachment, i) => {
                                                        if (attachment?.type === selectedAttachmentType) {
                                                            return (
                                                                <Image.PreviewGroup key={i + attachment.key + '_preview'}>
                                                                    {
                                                                        attachment?.attachments?.map((attachment, index) => (
                                                                            <Col key={i + '_' + index + attachment.key + '_col'}>
                                                                                {
                                                                                    attachment?.extension === 'pdf'|| attachment?.extension === 'PDF' ? (
                                                                                        <Button
                                                                                            key={i + '.' + index + attachment?.key + '_pdf'}
                                                                                            type={'dashed'}
                                                                                            onClick={() => this._handlePDFDownload(attachment?.url)}
                                                                                        >
                                                                                            {'Show PDF File'}
                                                                                        </Button>
                                                                                    )
                                                                                        :
                                                                                        <Image
                                                                                            key={i + '.' + index + attachment?.key + '_img'}
                                                                                            width={144}
                                                                                            height={144}
                                                                                            src={attachment?.url}
                                                                                            style={styles.imgStyles}
                                                                                            fallback={BROKEN_IMG}
                                                                                        />
                                                                                }
                                                                            </Col>
                                                                        ))
                                                                    }
                                                                </Image.PreviewGroup>
                                                            )
                                                        }
                                                        return null
                                                    })

                                                }
                                            </Row>
                                        </Card>
                                    )
                                )
                            }
                        </Space>
                    ) :
                        <Empty description={'No documents found'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Card>
        )
    }
}

// JSS Styles
const styles = {
    containerStyles: {
        height: '200px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imgStyles: {
        objectFit: 'contain',
        border: '1px solid rgba(0, 0, 0, .1)'
    }
}

// PropTypes Validation
Documents.propTypes = {
    applicationDetails: PropTypes.object
}

Documents.defaultProps = {
    applicationDetails: {}
}

// Map State To Props (Redux Store Passed Down)
const mapStateToProps = (state) => ({
    applicationDetails: state.application.applicationDetails,
})

export default connect(mapStateToProps, null)(Documents)