import axios from "axios"
import { VERIFIER } from '../../Config/config'

// import actions and reducers
import { setEndPointIconData, setRouteDistance, setVisitedPathData } from '../Reducers/routeStatisticsReducer'
import { setIsDateOrgFilterLoading } from '../Reducers/commonReducer'
import { showAlert } from "./commonActions"


// transforming verifier route data into deckgl triplayer format
const transformInTripLayerFormat = (rawData) => {
    const triplayerDataTemplate = [
        {
            waypoints: []
        }
    ]

    // generating data for deckgl trip layer
    rawData?.coordinates?.forEach(item => {
        triplayerDataTemplate[0]?.waypoints.push({ coordinates: item })
    })
    return triplayerDataTemplate
}

const transformInIconLayerFormat = (rawData) => {
    const iconlayerDataTemplate = rawData.map(({ longitude, latitude, ...items }) => ({
        ...items,
        coordinates: [Number(longitude),Number(latitude)]
    }));
    return iconlayerDataTemplate;
}


// get visited path of individual verifier
const getVerifierVisitedPath = (params) => {
    return async dispatch => {
        const today = new Date(params.dateTill).toDateString() === new Date().toDateString()
        const currentTime = today ? new Date().toTimeString().split(' ')[0] : '23:59:59'
        dispatch(setIsDateOrgFilterLoading(true))

        axios.get(`${VERIFIER.VISITED_ROUTE}?user_id=${params.agent_id}&dateFrom=${params.dateFrom}+00:00:00&dateTill=${params.dateTill}+${currentTime}&path=true`)
            .then(res => {
                // console.log(res, 'res')
                if (res?.status === 200 && res?.data?.data?.path?.length > 0) {
                    dispatch(setRouteDistance(`${Number.parseFloat(res?.data?.data?.distance).toFixed(2)} km`))
                    const parsedData = res?.data?.data

                    return parsedData

                }
                dispatch(setIsDateOrgFilterLoading(false))
                return []
            })
            .then(res => {
                if (res) {
                    // console.log(JSON.parse(res?.path), 'res')
                    // transform call to format data into waypoints format for deckgl trip layer
                    const formattedData = transformInTripLayerFormat(JSON.parse(res?.path))
                    const formattedIconData = transformInIconLayerFormat(res?.points)
                    dispatch(dispatch => {
                        dispatch(setVisitedPathData(formattedData))
                        dispatch(setEndPointIconData(formattedIconData))
                        dispatch(setIsDateOrgFilterLoading(false))
                    }
                    )
                }
                dispatch(setIsDateOrgFilterLoading(false))
                return []
            })
            .catch(err => {
                dispatch(setIsDateOrgFilterLoading(false))
                dispatch(setRouteDistance(0))
                dispatch(setVisitedPathData([]))
                dispatch(setEndPointIconData([]))
                showAlert('error', 'No Points Found')
                console.error(err)
            })
    }
}

export { getVerifierVisitedPath }